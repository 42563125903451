export const AppName = process.env.REACT_APP_BUILD_TARGET || 'repairy';

export const BookingStatus = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  DONE: 'done',
  CANCELLED: 'cancelled',
};

// eslint-disable-next-line
export const EmailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

// eslint-disable-next-line
export const RegoPattern = /^[a-zA-Z0-9_\.-]+/i;

// eslint-disable-next-line
export const PhonePattern = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
// export const IDPhonePattern = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/; ID pattern

export const ServicePackageMap = {
  'logbook': 'Logbook Service',
  'basic': 'Basic Service',
  'others': 'Others',
  'tyres': 'Tyres & Wheels',
}

export const ConfigDefault = {
  reminder: 6,
  reminderAlert: 2,
}

// eslint-disable-next-line
export const WorkshopCodePattern = /^[a-z0-9-_]+$/i;

// eslint-disable-next-line
export const AlphaSenderPattern = /^[a-zA-Z0-9\-]+$/i;

export const CancelReason = {
  NO_CUSTOMER: 'customer_didnt_come',
  CUSTOMER_CANCEL: 'customer_cancel',
  REVERT: 'revert',
};

export const ReviewTemplates = {
  // eslint-disable-next-line
  Thanks: 'Hi {customerName}, thanks for servicing your car with us. Please consider helping us by leaving a review at {reviewLink}. Your feedback means a lot',
}

export const TimezoneOptions = [
  { value: 'Australia/Sydney', label: 'Australia/Sydney' },
  { value: 'Australia/Melbourne', label: 'Australia/Melbourne' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu UTC-10' },
  { value: 'America/Anchorage', label: 'America/Anchorage UTC-9' },
  { value: 'America/Los_Angeles', label: 'America/Los Angeles UTC-8' },
  { value: 'America/Denver', label: 'America/Denver UTC-7' },
  { value: 'America/Chicago', label: 'America/Chicago UTC-6' },
  { value: 'America/Detroit', label: 'America/Detroit UTC-5' },
];

export const ServiceReminderListMax = 4;

export const isReviewEnabled = !process.env.REACT_APP_DISABLE_REVIEWS;

export const TyreWheelJobMap = {
  'w01': 'Wheel Alignment',
  'w02': 'Wheel Balancing',
  't01': 'Puncture Repair',
  't02': 'Tyre Purchase',
}

export const isNewLogin = !!process.env.REACT_APP_NEW_LOGIN_UI;
export const isBrandDemoMode = !!process.env.REACT_APP_BRAND_DEMO;
export const isBrandMode = !!process.env.REACT_APP_BRAND_DASHBOARD;

export const QuoteStatus = {
  PENDING: 'pending',
  RESPONSED: 'responsed',
  CONVERTED: 'converted',
  CANCELLED: 'cancelled',
};

export const isNewAnalytics = !!process.env.REACT_APP_NEW_ANALYTICS;
export const onboardingTourId = process.env.REACT_APP_INTERCOM_ONBOARD_TOUR_ID;
export const onboardingTourDelay = process.env.REACT_APP_INTERCOM_ONBOARD_TOUR_DELAY || 1000;
export const onboardingTourMenu = process.env.REACT_APP_INTERCOM_ONBOARD_TOUR_MENU;

export const isFBRecomMode = !!process.env.REACT_APP_FB_RECOM_MODE;

export const FlytreeFBBetaShop = (
    process.env.REACT_APP_FTR_FB_BETAS || ''
  ).split('||');

export const ReviewReplyChannels = (
  process.env.REACT_APP_REVIEW_REPLY_CHANNELS || 'all'
).split('||');

